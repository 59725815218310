// File: src/components/QueryComponent/QueryResultCard.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ResultView from './ResultView';
import ControlPanel from './ControlPanel';
import ActionPanel from './ActionPanel';
import Card from '../Card';
import AlignedButtonLayout from './AlignedButtonLayout';
import * as XLSX from 'xlsx';
import { X, Info, ChevronUp, ChevronDown, MessageSquarePlus } from 'lucide-react';
import QueryInput from './QueryInput';
import useFollowUpQuery from '../../hooks/useFollowUpQuery';

const IntermediatePopup = ({ position, onClick }) => (
  <div 
    className="fixed z-50 cursor-pointer intermediate-popup"
    style={{ 
      left: `${position.x}px`, 
      top: `${position.y}px`,
      transform: 'translate(-50%, -50%)'
    }}
    onClick={onClick}
  >
    <div className="w-10 h-10 rounded-full bg-white shadow-custom animate-popIn flex items-center justify-center">
      <img src="/logo512.png" alt="App Icon" className="w-8 h-8" />
    </div>
  </div>
);

const QueryResultCard = ({ 
  query, 
  currentUser, 
  onSaveQuery, 
  onExplanationClick,
  isExplanationLoading,
  isDashboardMode = false,
  onSaveCardSettings,
  cardWidth,
  onCardWidthChange,
  onFollowUpQuery,
  onClose,
  isAdmin,
  isLoading
}) => {
  const [viewMode, setViewMode] = useState((query.cardSettings && query.cardSettings.viewMode) || 'table');
  const [chartType, setChartType] = useState((query.cardSettings && query.cardSettings.chartType) || 'bar');
  const [selectedXAxis, setSelectedXAxis] = useState((query.cardSettings && query.cardSettings.selectedXAxis) || '');
  const [selectedYAxes, setSelectedYAxes] = useState((query.cardSettings && query.cardSettings.selectedYAxes) || '');
  const [sortColumn, setSortColumn] = useState((query.cardSettings && query.cardSettings.sortColumn) || '');
  const [sortOrder, setSortOrder] = useState((query.cardSettings && query.cardSettings.sortOrder) || 'desc');
  const [processedData, setProcessedData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [localCardWidth, setLocalCardWidth] = useState(cardWidth || '100%');
  const [showChartControls, setShowChartControls] = useState(false);
  const [isExplanationCollapsed, setIsExplanationCollapsed] = useState(false);
  const cardRef = useRef(null);
  const popupRef = useRef(null);
  const modalRef = useRef(null);

  const {
    selectedText,
    showIntermediatePopup,
    intermediatePopupPosition,
    showFollowUpInput,
    isFromHighlight,
    handleTextSelection,
    handleIntermediatePopupClick,
    handleFollowUpSubmit,
    handleClickOutside,
    handleEscKey,
    setShowFollowUpInput,
    resetFollowUpState
  } = useFollowUpQuery(async (submittedQuery, selectedText, originalSqlQuery) => {
    setShowFollowUpInput(false);
    await onFollowUpQuery(submittedQuery, selectedText, originalSqlQuery);
  });
  
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleOutsideClick = (event) => handleClickOutside(event, modalRef);

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [handleClickOutside, handleEscKey]);

  useEffect(() => {
    setLocalCardWidth(cardWidth || '100%');
  }, [cardWidth]);

  useEffect(() => {
    if (query && query.result && query.result.length > 0) {
      const firstRow = query.result[0];
      const keys = Object.keys(firstRow);
      
      const dateColumn = keys.find(key => 
        typeof firstRow[key] === 'string' && 
        (firstRow[key].match(/^\d{4}-\d{2}-\d{2}/) || firstRow[key].match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/))
      ) || keys[0] || '';

      const valueColumns = keys.filter(key => typeof firstRow[key] === 'number');

      setSelectedXAxis(query.cardSettings?.selectedXAxis || dateColumn);
      setSelectedYAxes(query.cardSettings?.selectedYAxes || valueColumns);
      setSortColumn(query.cardSettings?.sortColumn || dateColumn);
      setSortOrder(query.cardSettings?.sortOrder || 'desc');

      if (!query.cardSettings?.chartType) {
        setChartType('bar');
      }

      if (query.cardSettings) {
        setViewMode(query.cardSettings.viewMode || 'table');
        setChartType(query.cardSettings.chartType || 'bar');
        setSortColumn(query.cardSettings.sortColumn || '');
        setSortOrder(query.cardSettings.sortOrder || 'desc');
      }
    }
  }, [query]);

  const handleFollowUpButtonClick = () => {
    resetFollowUpState();
    setShowFollowUpInput(true);
  };
  
  const resultDimensions = useMemo(() => {
    return query && query.result && query.result.length > 0 ? Object.keys(query.result[0]).length : 0;
  }, [query]);

  const isSingleValue = useMemo(() => {
    return query && query.result && query.result.length === 1 && resultDimensions === 1;
  }, [query, resultDimensions]);

  const showControls = useMemo(() => {
    return query && query.result && query.result.length > 1 && resultDimensions >= 2;
  }, [query, resultDimensions]);

  const canShowGraph = useMemo(() => {
    if (!query || !query.result || query.result.length === 0) return false;
    if (query.result.length === 1 && resultDimensions <= 3) return false;
    return query.result.length > 1 && resultDimensions >= 2;
  }, [query, resultDimensions]);

  const handleCardWidthChange = useCallback((newWidth) => {
    if (!isMobile && onCardWidthChange) {
      setLocalCardWidth(newWidth);
      onCardWidthChange(newWidth);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ cardWidth: newWidth });
      }
    }
  }, [onCardWidthChange, isMobile, isDashboardMode, onSaveCardSettings]);

  const toggleViewMode = useCallback(() => {
    if (canShowGraph) {
      const newViewMode = viewMode === 'chart' ? 'table' : 'chart';
      setViewMode(newViewMode);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ ...query.cardSettings, viewMode: newViewMode });
      }
    }
  }, [canShowGraph, viewMode, isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleExcelDownload = useCallback(() => {
    if (processedData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(
        processedData.map(row => 
          Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value.displayValue || value])
          )
        )
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "query_result.xlsx");
    }
  }, [processedData]);

  const handleCloseCard = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const handleChartTypeChange = useCallback((newChartType) => {
    setChartType(newChartType);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, chartType: newChartType });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleSortChange = useCallback((column, order) => {
    setSortColumn(column);
    setSortOrder(order);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, sortColumn: column, sortOrder: order });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleAxisChange = useCallback((axis, value) => {
    if (axis === 'x') {
      setSelectedXAxis(value);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ ...query.cardSettings, selectedXAxis: value });
      }
    } else if (axis === 'y') {
      setSelectedYAxes(value);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ ...query.cardSettings, selectedYAxes: value });
      }
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const getCardTitle = useCallback(() => {
    let titleText;
    
    if (isDashboardMode && query.name) {
      titleText = query.name;
    } else {
      titleText = query.userQuery || "Query Result";
    }
  
    const isFollowUp = query.isFollowUp || 
                       titleText.toLowerCase().includes('follow-up') || 
                       titleText.toLowerCase().includes('follow up') ||
                       (query.originalQuery && query.originalQuery !== query.userQuery);
  
    if (isFollowUp) {
      titleText = titleText.replace(/^(follow-up:|follow up:)\s*/i, '');
      titleText = `Follow-up: ${titleText}`;
    }
  
    if (!isDashboardMode) {
      const words = titleText.split(' ');
      if (words.length > 10) {
        titleText = `${words.slice(0, 10).join(' ')}...`;
      }
    }
  
    return `${titleText}`;
  }, [query.name, query.userQuery, query.isFollowUp, query.originalQuery, isDashboardMode]);

  const cardStyle = useMemo(() => {
    const baseStyle = "bg-white shadow-card rounded-lg p-4 mb-4 flex flex-col";
    if (isDashboardMode) {
      return `${baseStyle}`;
    } else {
      return `${baseStyle} border-l-4 border-secondary bg-gradient-to-r from-secondary/5 to-secondary/20`;
    }
  }, [isDashboardMode]);

  const handleAdjustChart = useCallback(() => {
    setShowChartControls(prev => !prev);
  }, []);

  const toggleExplanationCollapse = () => {
    setIsExplanationCollapsed(prev => !prev);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="relative">
      <Card 
        ref={cardRef}
        className={`overflow-hidden relative flex flex-col ${cardStyle}`}
        style={{ 
          width: isMobile ? '100%' : (isDashboardMode ? localCardWidth : '100%'),
          minHeight: '400px',
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-700">{getCardTitle()}</h3>
          <button
            onClick={handleCloseCard}
            className="p-1 rounded-full transition-colors duration-200 hover:bg-gray-200"
            aria-label="Close result"
          ><X size={18} className="text-gray-700" />
          </button>
        </div>
        {query.truncationMessage && (
          <div className="mb-4 p-3 rounded bg-blue-100 text-blue-800 flex items-start">
            <Info className="mr-2 flex-shrink-0 mt-1" size={18} />
            <p>{query.truncationMessage}</p>
          </div>
        )}
        <AlignedButtonLayout
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          showControls={showControls}
          chartType={chartType}
          setChartType={handleChartTypeChange}
          downloadExcel={handleExcelDownload}
          canShowGraph={canShowGraph}
          isSingleValue={isSingleValue}
          hasResults={query.result && query.result.length > 0}
          onAdjustChart={handleAdjustChart}
          isChartAdjustVisible={showChartControls}
          isMobile={isMobile}
        />
        {showControls && viewMode === 'chart' && showChartControls && (
          <ControlPanel
            selectedXAxis={selectedXAxis}
            setSelectedXAxis={(value) => handleAxisChange('x', value)}
            selectedYAxes={selectedYAxes}
            setSelectedYAxes={(value) => handleAxisChange('y', value)}
            sortColumn={sortColumn}
            setSortColumn={(column) => handleSortChange(column, sortOrder)}
            sortOrder={sortOrder}
            setSortOrder={(order) => handleSortChange(sortColumn, order)}
            columns={query.result && query.result.length > 0 ? Object.keys(query.result[0]) : []}
            schema={query.schema}
            isVisible={showChartControls}
          />
        )}
        <div className="flex-grow overflow-y-auto mt-4 pb-2" onMouseUp={handleTextSelection}>
          <ResultView 
            result={query}
            viewMode={canShowGraph ? viewMode : 'table'}
            chartType={chartType}
            selectedXAxis={selectedXAxis}
            selectedYAxes={selectedYAxes}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            isSingleValue={isSingleValue}
            onProcessedDataChange={setProcessedData}
          />
        </div>
        <div className="mt-auto flex justify-between items-center">
          <button
            onClick={handleFollowUpButtonClick}
            className="p-1.5 rounded-full transition-all duration-200 bg-white hover:bg-gray-100 border border-gray-200 shadow-sm hover:shadow-md"
            title="Ask follow-up question"
            style={{
              transform: 'scale(1)',
              transition: 'transform 0.2s ease-in-out'
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <img src="/favicon.ico" alt="App Icon" className="w-6 h-6" />
          </button>
          <ActionPanel 
            result={query} 
            onSaveQuery={onSaveQuery}
            isSavedQuery={query.isSavedQuery}
            onExplanationClick={onExplanationClick}
            isExplanationLoading={isExplanationLoading}
            explanation={query.explanation}
            isDashboardMode={isDashboardMode}
            isAdmin={isAdmin}
            isEmptyResult={!query.result || query.result.length === 0}
          />     
        </div>
        {!isDashboardMode && query.explanation && (
          <div className="mt-4 bg-gray-50 rounded-md p-4 transition-all duration-300 ease-in-out">
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={toggleExplanationCollapse}
            >
              <h4 className="text-md font-semibold text-gray-700">Behind the Query</h4>
              {isExplanationCollapsed ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
            </div>
            <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${isExplanationCollapsed ? 'max-h-0' : 'max-h-96'}`}>
              <p className="text-sm text-gray-600 whitespace-pre-wrap">{query.explanation}</p>
            </div>
          </div>
        )}
      </Card>
      {showIntermediatePopup && (
        <IntermediatePopup 
          position={intermediatePopupPosition} 
          onClick={handleIntermediatePopupClick} 
        />
      )}
      {showFollowUpInput && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl">
            <h3 className="text-lg font-semibold mb-4">Follow-up Query</h3>
            {isLoading ? (
              <div className="flex justify-center items-center h-24">
                <p>Loading...</p>
              </div>
            ) : (
              <QueryInput
                onSubmit={(submittedQuery, selectedText) => handleFollowUpSubmit(submittedQuery, selectedText, query.sqlQuery)}
                isFollowUpMode={true}
                selectedText={isFromHighlight ? selectedText : (getCardTitle().slice(0, 20))}
                isCardTitle={!isFromHighlight}
                isLoading={isLoading}
              />
            )}
            <button 
              className="mt-4 text-sm text-gray-600 hover:text-gray-800"
              onClick={() => {
                setShowFollowUpInput(false);
                resetFollowUpState();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryResultCard;