import React, { useState } from 'react';
import { AlertTriangle, Copy, ChevronDown, ChevronUp, Lightbulb } from 'lucide-react';
import Card from '../Card';
import Button from '../Button';
import { useAuth } from '../../contexts/AuthContext';

const SuggestedAction = ({ action }) => (
  <div className="bg-secondary bg-opacity-20 border-l-4 border-primary-500 rounded-md p-4 my-4 flex items-start">
    <Lightbulb className="text-primary-500 mr-3 flex-shrink-0 mt-1" size={24} />
    <div>
      <h4 className="font-semibold text-primary-700 mb-1">Suggested Action</h4>
      <p className="text-primary-600">{action}</p>
    </div>
  </div>
);

const QueryError = ({ error, onClose }) => {
  const [copiedError, setCopiedError] = useState(false);
  const [expandedTry, setExpandedTry] = useState(null);
  const { isAdmin } = useAuth();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedError(true);
      setTimeout(() => setCopiedError(false), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const toggleTryExpansion = (index) => {
    setExpandedTry(expandedTry === index ? null : index);
  };

  const formatErrorContent = () => {
    let content = `Error Details:\n\n`;
    content += `Query ID: ${error.documentId || 'N/A'}\n`;
    content += `Error message: ${error.message || 'N/A'}\n`;

    if (isAdmin) {
      content += `User ID: ${error.userId || 'N/A'}\n`;
      content += `User question: ${error.userQuestion || 'N/A'}\n`;
      content += `Big Query Error: ${error.bigQueryError || 'N/A'}\n`;
      content += `Retry Count: ${error.retryCount || 'N/A'}\n`;
      content += `Retry Result: ${error.retryResult || 'N/A'}\n`;
      content += `Status: ${error.status || 'N/A'}\n`;
      content += `End Time: ${error.endTime || 'N/A'}\n\n`;

      if (error.queryVersions && error.queryVersions.length > 0) {
        error.queryVersions.forEach((version, index) => {
          content += `Query Version ${index + 1}:\n`;
          content += `SQL Query:\n${version.sqlQuery || 'N/A'}\n`;
          content += `Error: ${version.error || 'N/A'}\n\n`;
        });
      }
    }

    return content;
  };

  let errorMessage = "It seems we couldn't process your query this time.";
  let errorDetails = "";
  let suggestedAction = "Please try rephrasing your question or contact support if the issue persists.";

  if (error.bigQueryError && error.bigQueryError.includes("Function not found")) {
    errorMessage = "The query used a function that is not available in our system.";
    errorDetails = error.bigQueryError;
    suggestedAction = "Please try using a different approach to your question that doesn't require this specific function.";
  } else if (error.retryResult === "Failed" && error.retryCount > 0) {
    errorMessage = "We encountered multiple issues while processing your query.";
    errorDetails = `The query failed after ${error.retryCount + 1} attempts.`;
  } else if (error.response && error.response === "[]") {
    errorMessage = "The query completed, but no results were returned.";
    suggestedAction = "This might mean that there's no data matching your specific criteria. Try broadening your search terms.";
  }

  return (
    <Card className="bg-gray-50 border-l-4 border-gray-500 text-gray-700 p-4 mb-4 relative">
      <div className="flex items-center mb-4">
        <AlertTriangle className="mr-2 text-gray-700" />
        <h3 className="text-lg font-semibold text-gray-700">Something Went Wrong</h3>
        <button
          onClick={onClose}
          className="ml-auto p-1 rounded-full transition-colors duration-200 hover:bg-gray-200"
          aria-label="Close error message"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      <p className="mb-2">{errorMessage}</p>
      <SuggestedAction action={suggestedAction} />
      <p className="mb-2"><strong>Query ID:</strong> {error.documentId || 'N/A'}</p>
      
      {errorDetails && (
        <p className="mb-2"><strong>Error details:</strong> {errorDetails}</p>
      )}
      
      {isAdmin && (
        <div className="mt-4">
          <h4 className="font-semibold mb-2">Admin Details:</h4>
          {error.queryVersions && error.queryVersions.length > 0 ? (
            error.queryVersions.map((version, index) => (
              <div key={index} className="mb-4 bg-white rounded-md p-2">
                <div 
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleTryExpansion(index)}
                >
                  <h5 className="font-semibold">Query Version {index + 1}</h5>
                  {expandedTry === index ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                </div>
                {expandedTry === index && (
                  <div className="mt-2">
                    <p><strong>Error details:</strong> {version.error || 'N/A'}</p>
                    <p><strong>SQL Query:</strong></p>
                    <pre className="bg-gray-100 p-2 rounded mt-1 overflow-x-auto">
                      {version.sqlQuery || 'N/A'}
                    </pre>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p><strong>Error details:</strong> {error.message || 'N/A'}</p>
          )}
          <p><strong>User ID:</strong> {error.userId || 'N/A'}</p>
          <p><strong>Retry Count:</strong> {error.retryCount || 'N/A'}</p>
          <p><strong>Retry Result:</strong> {error.retryResult || 'N/A'}</p>
          <p><strong>Status:</strong> {error.status || 'N/A'}</p>
          <p><strong>End Time:</strong> {error.endTime || 'N/A'}</p>
          <p><strong>Big Query Error:</strong> {error.bigQueryError || 'N/A'}</p>
        </div>
      )}
      
      <div className="mt-4">
        <Button
          onClick={() => copyToClipboard(formatErrorContent())}
          icon={Copy}
          className="p-2"
          title={copiedError ? 'Copied!' : 'Copy Error Details'}
        >
          {copiedError ? 'Copied!' : 'Copy Error Details'}
        </Button>
      </div>
    </Card>
  );
};

export default QueryError;