import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import DynamicQuery from './pages/DynamicQuery';
import StaticDashboard from './pages/StaticDashboard';
import Login from './pages/SignIn';
import Signup from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { DashboardProvider } from './contexts/DashboardContext';
import './assets/bubblingLoader.css';

const PrivateRoute = ({ children }) => {
  const { currentUser, isAdmin } = useAuth();
  
  console.log('PrivateRoute: currentUser:', !!currentUser, 'isAdmin:', isAdmin);

  if (!currentUser) {
    console.log('PrivateRoute: Redirecting to login');
    return <Navigate to="/login" />;
  }
  
  console.log('PrivateRoute: Rendering child component with isAdmin:', isAdmin);
  return React.cloneElement(children, { isAdmin });
};

function AppContent() {
  const mainContentRef = useRef(null);
  const { currentUser, isAdmin, checkAdminStatus } = useAuth();

  useEffect(() => {
    if (currentUser) {
      console.log('AppContent: Current user detected, checking admin status');
      const refreshTokenAndCheckAdmin = async () => {
        const adminStatus = await checkAdminStatus(currentUser);
        console.log('AppContent: Admin status after check:', adminStatus);
      };

      refreshTokenAndCheckAdmin();

      // Refresh token and check admin status every 5 minutes
      const intervalId = setInterval(refreshTokenAndCheckAdmin, 5 * 60 * 1000);

      return () => clearInterval(intervalId);
    } else {
      console.log('AppContent: No current user');
    }
  }, [currentUser, checkAdminStatus]);

  console.log('AppContent: Rendering with isAdmin:', isAdmin);

  return (
    <Router>
      <Layout mainContentRef={mainContentRef}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/" element={<PrivateRoute><Home mainContentRef={mainContentRef} /></PrivateRoute>} />
          <Route path="/dynamic" element={<PrivateRoute><DynamicQuery mainContentRef={mainContentRef} /></PrivateRoute>} />
          <Route path="/static-dashboard" element={<PrivateRoute><StaticDashboard /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

function App() {
  useEffect(() => {
    const currentBuildTime = localStorage.getItem('buildTime');
    const newBuildTime = process.env.REACT_APP_BUILD_TIME;

    if (currentBuildTime !== newBuildTime) {
      localStorage.setItem('buildTime', newBuildTime);
      if (currentBuildTime) {
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <AuthProvider>
        <DashboardProvider>
          <AppContent />
        </DashboardProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;