import React, { useState } from 'react';
import { Copy, Code, FileText, FilePlus2, Info } from 'lucide-react';
import Button from '../Button';
import PopupCard from '../PopupCard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAuth } from '../../contexts/AuthContext';

const ActionPanel = ({ result, onSaveQuery, isSavedQuery, onExplanationClick, isExplanationLoading, explanation, isDashboardMode, isEmptyResult }) => {
  const [showSqlPopup, setShowSqlPopup] = useState(false);
  const [showLogsPopup, setShowLogsPopup] = useState(false);
  const [showLogicPopup, setShowLogicPopup] = useState(false);
  const [copiedResult, setCopiedResult] = useState(false);
  const [copiedSql, setCopiedSql] = useState(false);
  const { isAdmin } = useAuth();

  console.log('ActionPanel - isAdmin:', isAdmin);
  console.log('ActionPanel - Is result empty?', isEmptyResult);

  const copyToClipboard = (text, setCopiedState) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedState(true);
      setTimeout(() => setCopiedState(false), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const formatResultForCopy = (data) => {
    if (!Array.isArray(data)) {
      return JSON.stringify(data, null, 2);
    }
    return JSON.stringify(data.map(item => {
      if (typeof item !== 'object' || item === null) {
        return item;
      }
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => {
          if (value && typeof value === 'object' && 'displayValue' in value) {
            return [key, value.displayValue];
          }
          return [key, value];
        })
      );
    }), null, 2);
  };

  const formatLogs = () => {
    return `
User Query:
${result.userQuery}

Configuration Details:
${JSON.stringify(result.config, null, 2)}

SQL Generated by LLM:
${result.sqlQuery}

Response from BigQuery:
${formatResultForCopy(result.result)}

Document ID:
${result.documentId}
    `;
  };

  return (
    <div className="flex justify-end space-x-2 font-sans">
      <Button
        onClick={() => {
          setShowLogicPopup(true);
          if (!explanation) {
            onExplanationClick();
          }
        }}
        icon={Info}
        className="p-2"
        title="Show Logic"
      />

      <Button
        onClick={() => copyToClipboard(formatResultForCopy(result.result), setCopiedResult)}
        icon={Copy}
        className="p-2"
        title={copiedResult ? 'Copied!' : 'Copy Result'}
      />

      {isAdmin && (
        <>
          <Button
            onClick={() => setShowSqlPopup(true)}
            icon={Code}
            className="p-2"
            title="View SQL"
          />
          <Button
            onClick={() => setShowLogsPopup(true)}
            icon={FileText}
            className="p-2"
            title="View Logs"
          />
        </>
      )}

      {!isDashboardMode && !isSavedQuery && (
        <Button
          onClick={onSaveQuery}
          icon={FilePlus2}
          className="p-2"
          title="Save to Dashboard"
        />
      )}

      <PopupCard
        isOpen={showSqlPopup}
        onClose={() => setShowSqlPopup(false)}
        title="SQL Query"
        footer={
          <Button
            onClick={() => copyToClipboard(result.sqlQuery, setCopiedSql)}
            icon={Copy}
            className="p-2"
            title={copiedSql ? 'Copied!' : 'Copy SQL'}
          />
        }
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          <SyntaxHighlighter language="sql" style={docco} customStyle={{ fontSize: '0.875rem' }}>
            {result?.sqlQuery}
          </SyntaxHighlighter>
        </div>
      </PopupCard>

      <PopupCard
        isOpen={showLogsPopup}
        onClose={() => setShowLogsPopup(false)}
        title="Debug Logs"
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="mt-4 p-4 bg-gray-50 rounded-md max-h-[60vh] overflow-y-auto">
          <pre className="whitespace-pre-wrap break-words text-sm">
            {formatLogs()}
          </pre>
        </div>
      </PopupCard>

      <PopupCard
        isOpen={showLogicPopup}
        onClose={() => setShowLogicPopup(false)}
        title={isEmptyResult ? "Here's How I've Tried Finding the Data" : "Behind the Query"}
        footer={null}
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          {isExplanationLoading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin border-4 border-t-4 border-primary rounded-full w-8 h-8"></div>
            </div>
          ) : (
            <pre className="whitespace-pre-wrap break-words text-sm">
              {explanation}
            </pre>
          )}
        </div>
      </PopupCard>
    </div>
  );
};

export default ActionPanel;