import { format, parse, isValid } from 'date-fns';

// Define exempted columns
const EXEMPTED_COLUMNS = ['nir_number', 'product_code', 'destination_cell', 'shape_code', 'destination_cell', 'press',];

export const formatDate = (dateValue, schemaType) => {
  if (!dateValue) return '';

  // Ensure dateString is always a string
  const dateString = typeof dateValue === 'object' && dateValue !== null
    ? (dateValue.value || dateValue.toString())
    : String(dateValue);

  const formats = [
    { format: 'yyyy-MM-dd HH:mm:ss', output: 'dd/MM/yyyy HH:mm:ss' },
    { format: 'yyyy-MM-dd', output: 'dd/MM/yyyy' },
    { format: 'yyyy-MM', output: 'MM/yyyy' },
    { format: 'MM-yyyy', output: 'MM/yyyy' },
    { format: 'yyyy', output: 'yyyy' },
    { format: 'MM/yyyy', output: 'MM/yyyy' },
    { format: 'MM/dd/yyyy', output: 'dd/MM/yyyy' },
    { format: 'dd/MM/yyyy', output: 'dd/MM/yyyy' },
    { format: 'yyyy/MM/dd', output: 'dd/MM/yyyy' },
    { format: 'MM yy', output: 'MM/yyyy' }
  ];

  // If we have schema information, use it to determine the best format
  if (schemaType) {
    if (schemaType.toLowerCase().includes('timestamp')) {
      const parsedDate = new Date(dateString);
      return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy HH:mm:ss') : dateString;
    } else if (schemaType.toLowerCase().includes('date')) {
      const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDate)) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
    }
  }

  // If no schema type or it doesn't match, try the predefined formats
  for (const { format: formatString, output } of formats) {
    const parsedDate = parse(dateString, formatString, new Date());
    if (isValid(parsedDate)) {
      // If the original format includes time, use dd/MM/yyyy HH:mm:ss
      if (formatString.includes('HH:mm:ss')) {
        return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
      }
      // If the original format includes day, use dd/MM/yyyy
      if (formatString.includes('dd') || formatString.includes('DD')) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
      // If the original format only includes month and year, use MM/yyyy
      return format(parsedDate, output);
    }
  }

  // If all else fails, return the original string
  return dateString;
};

export const formatNumber = (value, columnName) => {
  // Check if the column name is in the exempted list
  if (EXEMPTED_COLUMNS.includes(columnName)) {
    return value;
  }

  if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    const absNum = Math.abs(num);
    
    if (absNum >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (absNum >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (absNum >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num.toLocaleString('en-US', { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 2 
      });
    }
  }
  return value;
};

export const formatColumnName = (name) => {
  if (typeof name !== 'string') {
    console.warn(`Unexpected column name type: ${typeof name}`, name);
    return String(name);
  }
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const processData = (rawData, schema) => {
  if (!Array.isArray(rawData)) {
    console.warn('Expected array for rawData, got:', typeof rawData);
    return [];
  }

  return rawData.map(item => {
    if (!item || typeof item !== 'object') {
      console.warn('Invalid item in rawData:', item);
      return {};
    }

    const processedItem = {};
    Object.entries(item).forEach(([key, value]) => {
      const fieldSchema = schema.fields.find(field => field.name === key);

      if (fieldSchema) {
        let displayValue, sortValue;
        switch (fieldSchema.type) {
          case 'DATE':
          case 'DATETIME':
          case 'TIMESTAMP':
          case 'STRING':
            if (typeof value === 'object' && value !== null && 'value' in value) {
              displayValue = formatDate(value.value, fieldSchema.type);
              sortValue = new Date(value.value).getTime();
            } else {
              displayValue = formatDate(value, fieldSchema.type);
              sortValue = new Date(value).getTime();
            }
            break;
          case 'INTEGER':
          case 'FLOAT':
          case 'NUMERIC':
            displayValue = formatNumber(value, key);
            sortValue = Number(value);
            break;
          default:
            displayValue = value;
            sortValue = value;
        }
        processedItem[key] = { displayValue, sortValue };
      } else {
        processedItem[key] = { displayValue: value, sortValue: value };
      }
    });
    return processedItem;
  });
};

export const sortData = (data, sortColumn, sortOrder) => {
  if (!Array.isArray(data) || data.length === 0 || !sortColumn) {
    return data;
  }

  return [...data].sort((a, b) => {
    const aValue = a[sortColumn]?.sortValue;
    const bValue = b[sortColumn]?.sortValue;

    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    }

    const compareResult = String(aValue).localeCompare(String(bValue));
    return sortOrder === 'asc' ? compareResult : -compareResult;
  });
};