import React, { useState, useEffect } from 'react';
import { ChevronDown, Plus, Edit } from 'lucide-react';
import Button from '../Button';

const DashboardList = ({ 
  dashboards, 
  selectedDashboard, 
  onSelectDashboard, 
  onNewDashboard, 
  onEditDashboard, 
  currentUser,
  isAdmin
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayedDashboardName, setDisplayedDashboardName] = useState('Select Dashboard');
  const [userSelectedDashboardId, setUserSelectedDashboardId] = useState(null);

  useEffect(() => {
    if (selectedDashboard) {
      setDisplayedDashboardName(selectedDashboard.name);
      setUserSelectedDashboardId(selectedDashboard.id);
    } else {
      setDisplayedDashboardName('Select Dashboard');
      setUserSelectedDashboardId(null);
    }
  }, [selectedDashboard]);

  const handleDashboardSelection = (dashboard) => {
    setIsDropdownOpen(false);
    setDisplayedDashboardName(dashboard.name);
    setUserSelectedDashboardId(dashboard.id);
    onSelectDashboard(dashboard);
  };

  useEffect(() => {
    if (userSelectedDashboardId) {
      const selectedDash = dashboards.find(d => d.id === userSelectedDashboardId);
      if (selectedDash) {
        setDisplayedDashboardName(selectedDash.name);
      }
    }
  }, [dashboards, userSelectedDashboardId]);

  return (
    <div className="flex flex-wrap items-center justify-between">
      <div className="flex items-center flex-grow">
        <div className="relative inline-block text-left mr-2">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="inline-flex items-center justify-start text-lg font-bold text-gray-700 hover:text-primary transition-colors duration-200 focus:outline-none bg-white hover:bg-background px-4 py-2 rounded-full border-2 border-primary"
          >
            <span className="truncate max-w-[200px]">
              {displayedDashboardName}
            </span>
            <ChevronDown 
              className={`ml-2 h-5 w-5 flex-shrink-0 transition-transform duration-200 ease-in-out text-primary ${
                isDropdownOpen ? 'transform rotate-180' : ''
              }`}
              aria-hidden="true" 
            />
          </button>
          {isDropdownOpen && dashboards.length > 0 && (
            <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md bg-white ring-2 ring-primary focus:outline-none z-10">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {dashboards.map((dashboard) => (
                  <button
                    key={dashboard.id}
                    onClick={() => handleDashboardSelection(dashboard)}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-background hover:text-primary transition-colors duration-200"
                    role="menuitem"
                  >
                    {dashboard.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex space-x-1">
          {selectedDashboard && (currentUser && (selectedDashboard.userId === currentUser.email || isAdmin)) && (
            <Button
              onClick={() => onEditDashboard(selectedDashboard)}
              icon={Edit}
              className="text-primary p-1"
              title="Edit Dashboard"
            >
              <span className="hidden sm:inline ml-1">Edit Dashboard</span>
            </Button>
          )}
          <Button
            onClick={onNewDashboard}
            icon={Plus}
            className="text-primary p-1"
            title="New Dashboard"
          >
            <span className="hidden sm:inline ml-1">New Dashboard</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardList;