import React, { createContext, useState, useContext, useEffect } from 'react';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedDashboard, setSelectedDashboard] = useState(() => {
    const saved = localStorage.getItem('selectedDashboard');
    if (!saved) {
      return null;
    }
    try {
      const parsed = JSON.parse(saved);
      return parsed && typeof parsed === 'object' ? parsed : null;
    } catch (error) {
      console.error('Error parsing selectedDashboard from localStorage:', error);
      localStorage.removeItem('selectedDashboard');
      return null;
    }
  });

  useEffect(() => {
    if (selectedDashboard) {
      try {
        localStorage.setItem('selectedDashboard', JSON.stringify(selectedDashboard));
      } catch (error) {
        console.error('Error stringifying selectedDashboard for localStorage:', error);
      }
    } else {
      localStorage.removeItem('selectedDashboard');
    }
  }, [selectedDashboard]);

  const updateSelectedDashboard = (dashboard) => {
    if (dashboard && typeof dashboard === 'object') {
      setSelectedDashboard(prevDashboard => ({
        ...prevDashboard,
        ...dashboard,
        queries: dashboard.queries.map(query => ({
          ...query,
          cardSettings: {
            ...(prevDashboard?.queries.find(q => q.id === query.id)?.cardSettings || {}),
            ...(query.cardSettings || {})
          }
        }))
      }));
    } else {
      console.error('Invalid dashboard object provided to updateSelectedDashboard');
      setSelectedDashboard(null);
    }
  };

  const updateDashboardQuery = (queryId, updates) => {
    setSelectedDashboard(prevDashboard => {
      if (!prevDashboard) return null;
      return {
        ...prevDashboard,
        queries: prevDashboard.queries.map(query =>
          query.id === queryId ? { ...query, ...updates } : query
        )
      };
    });
  };

  const clearSelectedDashboard = () => {
    setSelectedDashboard(null);
  };

  return (
    <DashboardContext.Provider value={{ selectedDashboard, updateSelectedDashboard, updateDashboardQuery, clearSelectedDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};