import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChartView from './ChartView';
import TableView from './TableView';
import { processData, sortData, formatColumnName } from './dataProcessing';

const ResultView = ({ 
  result, 
  viewMode, 
  chartType, 
  selectedXAxis, 
  selectedYAxes, 
  sortColumn, 
  sortOrder, 
  isSingleValue, 
  onProcessedDataChange 
}) => {
  const [processedData, setProcessedData] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('ResultView props:', { result, viewMode, chartType, selectedXAxis, selectedYAxes, sortColumn, sortOrder, isSingleValue });
  }, [result, viewMode, chartType, selectedXAxis, selectedYAxes, sortColumn, sortOrder, isSingleValue]);

  useEffect(() => {
    if (result && Array.isArray(result.result) && result.schema) {
      try {
        const processed = processData(result.result, result.schema);
        setProcessedData(processed);
        setError(null);
        if (typeof onProcessedDataChange === 'function') {
          onProcessedDataChange(processed);
        }
      } catch (err) {
        console.error('Error processing data:', err);
        setError('An error occurred while processing the data. Please try again.');
      }
    } else {
      console.error('Invalid result structure:', result);
      setError('Invalid data structure received. Missing result array or schema.');
    }
  }, [result, onProcessedDataChange]);

  const sortedData = useMemo(() => {
    return sortData(processedData, sortColumn, sortOrder);
  }, [processedData, sortColumn, sortOrder]);

  const columns = useMemo(() => {
    if (result && result.schema && Array.isArray(result.schema.fields)) {
      return result.schema.fields.map(field => field.name);
    }
    console.warn('Invalid or missing schema:', result?.schema);
    return [];
  }, [result]);

  const formattedSelectedXAxis = useMemo(() => {
    if (!selectedXAxis || typeof selectedXAxis !== 'string' || !columns.includes(selectedXAxis)) {
      console.warn('Invalid selectedXAxis:', selectedXAxis);
      return columns[0] || ''; // Safely default to the first column
    }
    return selectedXAxis;
  }, [columns, selectedXAxis]);
  
  const formattedSelectedYAxes = useMemo(() => {
    if (!Array.isArray(selectedYAxes) || selectedYAxes.length === 0) {
      console.warn('Invalid selectedYAxes:', selectedYAxes);
      return [];
    }
    return selectedYAxes
      .filter(axis => typeof axis === 'string' && columns.includes(axis)) // Check that values are in columns
      .map(axis => columns.find(col => col.toLowerCase() === axis.toLowerCase()))
      .filter(Boolean);
  }, [columns, selectedYAxes]);  

  console.log('Formatted axes:', { formattedSelectedXAxis, formattedSelectedYAxes });

  if (error) {
    return (
      <div className="text-left text-red-600">
        <p className="text-base font-bold">{error}</p>
      </div>
    );
  }

  if (!result || !Array.isArray(result.result) || result.result.length === 0) {
    return (
      <div className="text-left">
        <p className="text-base">
          <span className="font-bold">It seems like your data doesn't contain what you're looking for.</span> We've checked thoroughly, but couldn't find any matches for your query. You might want to adjust your search terms or explore related data.
        </p>
      </div>
    );
  }

  if (isSingleValue && processedData.length > 0) {
    const [key, value] = Object.entries(processedData[0])[0];
    return (
      <div className="text-left">
        <p className="text-base">{formatColumnName(key)}: <span className="text-base font-bold">{value.displayValue}</span></p>
      </div>
    );
  }

  if (processedData.length === 1 || Object.keys(processedData[0] || {}).length === 1) {
    return (
      <div className="relative">
        <TableView data={sortedData} schema={result.schema} />
      </div>
    );
  }

  console.log('Formatted data for ChartView:', {
    data: sortedData,
    chartType,
    selectedXAxis: formattedSelectedXAxis,
    selectedYAxes: formattedSelectedYAxes,
    sortColumn,
    sortOrder,
    schema: result.schema
  });

  const renderContent = () => {
    if (viewMode === 'chart') {
      return (
        <div className={`chart-container ${isMobile ? 'px-0' : 'px-4'}`}>
          <ChartView 
            data={sortedData}
            chartType={chartType}
            selectedXAxis={formattedSelectedXAxis}
            selectedYAxes={formattedSelectedYAxes}
            schema={result.schema}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
          />
        </div>
      );
    } else {
      return (
        <div className="relative">
          <TableView 
            data={sortedData} 
            schema={result.schema}
            initialSortColumn={sortColumn}
            initialSortOrder={sortOrder}
          />
        </div>
      );
    }
  };

  return (
    <div className={`result-view-container ${isMobile ? 'p-0' : 'p-4'}`}>
      {renderContent()}
    </div>
  );
};

ResultView.propTypes = {
  result: PropTypes.shape({
    result: PropTypes.array,
    schema: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
      }))
    })
  }),
  viewMode: PropTypes.oneOf(['chart', 'table']).isRequired,
  chartType: PropTypes.string,
  selectedXAxis: PropTypes.string,
  selectedYAxes: PropTypes.arrayOf(PropTypes.string),
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  isSingleValue: PropTypes.bool,
  onProcessedDataChange: PropTypes.func
};

ResultView.defaultProps = {
  chartType: 'bar',
  selectedYAxes: [],
  isSingleValue: false
};

export default ResultView;