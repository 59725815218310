import React, { useEffect, useRef } from 'react';
import Card from './Card';
import Button from './Button';
import { X } from 'lucide-react';

const Modal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  title, 
  message, 
  isInput, 
  value, 
  onChange, 
  isLoading, 
  isShared, 
  onShareToggle,
  confirmText = 'Save',
  cancelText = 'Cancel',
  showButtons = true,
  children,
  confirmButtonClassName = '',
  confirmDisabled = false
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClickOutside}>
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-hidden" ref={modalRef}>
        <div className="bg-gray-100 px-6 py-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 transition-colors duration-150"
          >
            <X size={24} />
          </button>
        </div>
        <div className="px-6 py-4 overflow-y-auto">
          {message && <p className="mb-4">{message}</p>}
          {isInput && (
            <>
              <input
                type="text"
                value={value}
                onChange={onChange}
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter dashboard name"
              />
              {onShareToggle && (
                <div className="flex items-center justify-between mb-4">
                  <span className="mr-3">Share this query</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isShared}
                      onChange={onShareToggle}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              )}
            </>
          )}
          {children}
        </div>
        {showButtons && (
          <div className="bg-gray-50 px-6 py-4 flex justify-end">
            <Button onClick={onClose} className="mr-2">
              {cancelText}
            </Button>
            <Button 
              onClick={onSubmit} 
              loading={isLoading}
              className={confirmButtonClassName}
              disabled={confirmDisabled}
            >
              {confirmText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;