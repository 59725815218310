import React, { useState, useRef, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Menu } from 'lucide-react';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext';

const Layout = ({ children, mainContentRef }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { currentUser, isAdmin } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
  
    window.addEventListener('followUpQuery', handleScroll);
  
    return () => {
      window.removeEventListener('followUpQuery', handleScroll);
    };
  }, [mainContentRef]);

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} isAdmin={isAdmin} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white shadow-sm lg:hidden">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 flex justify-between items-center">
            <button
              onClick={() => setSidebarOpen(true)}
              className="text-gray-500 focus:outline-none focus:text-gray-600"
            >
              <Menu size={24} />
            </button>
            <img src={logo} alt="Company Logo" className="h-8 w-auto" />
          </div>
        </header>
        <main 
          ref={mainContentRef} 
          className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"
        >
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
            {React.cloneElement(children, { isAdmin, mainContentRef })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;