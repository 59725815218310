import React, { useState, useCallback, useEffect } from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import { useAuth } from '../../contexts/AuthContext';

const DashboardManager = ({ 
  dashboards,
  setDashboards,
  setError,
  userPreferences,
  setUserPreferences,
  handleQueryResult,
  setIsDashboardLoading,
  currentUser,
  isAdmin
}) => {
  const { selectedDashboard, updateSelectedDashboard, clearSelectedDashboard } = useDashboard();

  const saveUserPreferences = useCallback((dashboardId, preferences) => {
    const updatedPreferences = {
      ...userPreferences,
      [dashboardId]: preferences,
    };
    setUserPreferences(updatedPreferences);
    localStorage.setItem('dashboardPreferences', JSON.stringify(updatedPreferences));
  }, [userPreferences, setUserPreferences]);

  const fetchDashboards = useCallback(async () => {
    if (!currentUser) {
      console.log('Current user not available, skipping dashboard fetch');
      return;
    }
    try {
      console.log('Fetching dashboards...');
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'get',
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      const data = await response.json();
      console.log('Fetched dashboards:', data);
      setDashboards(data);
    } catch (error) {
      console.error('Error fetching dashboards:', error);
      setError('Failed to fetch dashboards. Please try again later.');
    }
  }, [currentUser, setDashboards, setError, isAdmin]);

  useEffect(() => {
    if (currentUser) {
      fetchDashboards();
    }
  }, [fetchDashboards, currentUser]);

  const handleSelectDashboard = useCallback(async (dashboard) => {
    console.log('Selecting dashboard:', dashboard);
    setIsDashboardLoading(true);
    setError(null);
    try {
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'getDashboardWithQueries',
            reportData: { dashboardId: dashboard.id },
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        }),
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched dashboard data:', data);
      if (data.error) {
        throw new Error(data.error);
      }
      const processedQueries = data.queries.map(query => {
        let parsedResult = query.result;
        if (typeof query.result === 'string') {
          try {
            parsedResult = JSON.parse(query.result);
          } catch (e) {
            console.error('Error parsing query result:', e);
            parsedResult = { error: 'Failed to parse result' };
          }
        }
        return {
          ...query,
          result: parsedResult,
          isSavedQuery: true,
          schema: query.schema || {
            fields: parsedResult && parsedResult.length > 0
              ? Object.keys(parsedResult[0]).map(key => ({
                  name: key,
                  type: typeof parsedResult[0][key] === 'number' ? 'FLOAT' : 'STRING'
                }))
              : []
          }
        };
      });
  
      const dashboardPreferences = userPreferences[dashboard.id] || {};
      const orderedQueries = dashboardPreferences.queryOrder
        ? dashboardPreferences.queryOrder
            .map(id => processedQueries.find(q => q.id === id))
            .filter(Boolean)
            .concat(processedQueries.filter(q => !dashboardPreferences.queryOrder.includes(q.id)))
        : processedQueries;
  
      const updatedDashboard = {
        ...data,
        queries: orderedQueries.map(query => ({
          ...query,
          cardSettings: {
            ...query.cardSettings,
            ...dashboardPreferences.querySettings?.[query.id],
          },
        })),
      };
  
      console.log('Updating selected dashboard:', updatedDashboard);
      updateSelectedDashboard(updatedDashboard);
    } catch (error) {
      console.error('Error fetching dashboard queries:', error);
      setError(`Failed to load dashboard: ${error.message}`);
    } finally {
      setIsDashboardLoading(false);
    }
  }, [currentUser, updateSelectedDashboard, userPreferences, setError, setIsDashboardLoading, isAdmin]);

  const handleCreateDashboard = async (newDashboardName, selectedQueries) => {
    if (!currentUser) {
      setError('Please log in to create a new dashboard.');
      return;
    }
    try {
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'create',
            reportData: {
              name: newDashboardName,
              queries: selectedQueries
            },
            userId: currentUser.email
          }
        })
      });
      const data = await response.json();
      setDashboards(prevDashboards => [...prevDashboards, data]);
      handleSelectDashboard(data);
      return data;
    } catch (error) {
      console.error('Error creating dashboard:', error);
      setError('Failed to create dashboard. Please try again.');
    }
  };

  const handleUpdateDashboard = async (updatedDashboard) => {
    try {
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'update',
            reportData: updatedDashboard,
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      handleSelectDashboard(updatedDashboard);

      const updatedPreferences = {
        ...userPreferences[updatedDashboard.id],
        queryOrder: updatedDashboard.queries.map(q => q.id),
      };
      saveUserPreferences(updatedDashboard.id, updatedPreferences);
    } catch (error) {
      console.error('Error updating dashboard:', error);
      setError(`Failed to update dashboard: ${error.message}`);
    }
  };

  const handleDeleteDashboard = useCallback(async (dashboardId) => {
    if (!currentUser) {
      setError('Please log in to delete a dashboard.');
      return;
    }
    try {
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'delete',
            reportData: { id: dashboardId },
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await response.json();
      setDashboards(prevDashboards => prevDashboards.filter(d => d.id !== dashboardId));
      if (selectedDashboard && selectedDashboard.id === dashboardId) {
        clearSelectedDashboard();
      }
      const { [dashboardId]: _, ...remainingPreferences } = userPreferences;
      setUserPreferences(remainingPreferences);
      localStorage.setItem('dashboardPreferences', JSON.stringify(remainingPreferences));
    } catch (error) {
      console.error('Error deleting dashboard:', error);
      setError('Failed to delete dashboard. Please try again.');
    }
  }, [currentUser, selectedDashboard, clearSelectedDashboard, userPreferences, setUserPreferences, setDashboards, setError, isAdmin]);

  const handleAddQueryToDashboard = async (dashboardId, queryToAdd) => {
    try {
      const response = await fetch('https://us-central1-miloubar-0000.cloudfunctions.net/handleDashboardOperation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'addQueryToDashboard',
            dashboardId: dashboardId,
            query: queryToAdd,
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      await fetchDashboards();
      if (selectedDashboard && selectedDashboard.id === dashboardId) {
        await handleSelectDashboard(selectedDashboard);
      }
      return true;
    } catch (error) {
      console.error('Error adding query to dashboard:', error);
      setError(`Failed to add query to dashboard: ${error.message}`);
      return false;
    }
  };

  return {
    fetchDashboards,
    handleSelectDashboard,
    handleCreateDashboard,
    handleUpdateDashboard,
    handleDeleteDashboard,
    handleAddQueryToDashboard,
    saveUserPreferences
  };
};

export default DashboardManager;