import React, { useCallback } from 'react';
import QueryComponent from '../QueryComponent/QueryComponent';

const QuerySection = ({ 
  onQueryResult, 
  onQueryStart,
  queryResult,
  isLoading,
  currentUser,
  onSaveQuery,
  onAddToDashboard,
  onFollowUpQuery,
  mainContentRef,
  submitQuery,
  onDashboardUpdate,
  dashboardManager,
  isQueryJustAdded,
  setIsQueryJustAdded
}) => {
  const handleQueryResult = useCallback((result) => {
    onQueryResult(result);
  }, [onQueryResult]);

  const handleQueryStart = useCallback(() => {
    onQueryStart();
  }, [onQueryStart]);

  const handleFollowUpQuery = useCallback((followUpQuery, highlightedText, originalSqlQuery) => {
    console.log('Follow-up query in QuerySection:', followUpQuery);
    console.log('Highlighted text:', highlightedText);
    console.log('Original SQL query:', originalSqlQuery);
    submitQuery(followUpQuery, true, highlightedText, originalSqlQuery);
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  
    if (mainContentRef && mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [submitQuery, mainContentRef]);

  return (
    <div className="rounded-lg p-0 mb-0">
      <QueryComponent 
        onQueryResult={handleQueryResult} 
        onQueryStart={handleQueryStart}
        queryResult={queryResult}
        isLoading={isLoading}
        currentUser={currentUser}
        onSaveQuery={onSaveQuery}
        onAddToDashboard={onAddToDashboard}
        onFollowUpQuery={handleFollowUpQuery}
        mainContentRef={mainContentRef}
        dashboardManager={dashboardManager}
        onDashboardUpdate={onDashboardUpdate}
        isQueryJustAdded={isQueryJustAdded}
        setIsQueryJustAdded={setIsQueryJustAdded}
      />
    </div>
  );
};

export default QuerySection;