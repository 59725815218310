import React from 'react';
import { AlertCircle } from 'lucide-react';
import QueryResultCard from '../QueryComponent/QueryResultCard';

const DashboardContent = ({
  error,
  isDashboardLoading,
  selectedDashboard,
  currentUser,
  onSaveCardSettings,
  onFollowUpQuery,
  onSaveQuery,
  onExplanationClick
}) => {
  if (error) {
    return (
      <div className="mt-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
        <div className="flex">
          <AlertCircle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isDashboardLoading) {
    return (
      <div className="mt-10 text-center">
        <p className="text-gray-600">Loading dashboard...</p>
      </div>
    );
  }

  if (selectedDashboard && selectedDashboard.queries && selectedDashboard.queries.length > 0) {
    return (
      <div className="space-y-4">
        {selectedDashboard.queries.map((query) => (
          <div 
            key={`${query.id}-${query.cardSettings?.cardWidth || '100%'}`} 
            className="w-full"
          >
            <QueryResultCard
              query={query}
              currentUser={currentUser}
              isDashboardMode={true}
              onSaveCardSettings={(settings) => onSaveCardSettings(query.id, settings)}
              cardWidth={query.cardSettings?.cardWidth || "100%"}
              onCardWidthChange={(width) => onSaveCardSettings(query.id, { cardWidth: width })}
              onFollowUpQuery={onFollowUpQuery}
              onSaveQuery={onSaveQuery}
              onExplanationClick={() => onExplanationClick(query)}
              isExplanationLoading={false}
              truncationMessage={query.truncationMessage || ''}
            />
          </div>
        ))}
      </div>
    );
  }

  if (selectedDashboard) {
    return (
      <div className="mt-8 text-center">
        <p className="text-gray-600">This dashboard has no queries.</p>
      </div>
    );
  }

  return (
    <div className="mt-8 text-center">
      <p className="text-gray-600">No dashboard selected. Please select or create a dashboard.</p>
    </div>
  );
};

export default DashboardContent;