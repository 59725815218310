import React from 'react';
import Button from '../Button';
import ChartToggle from './ChartToggle';
import { Table, BarChart, Download, Sliders } from 'lucide-react';

const AlignedButtonLayout = ({
  viewMode,
  toggleViewMode,
  showControls,
  chartType,
  setChartType,
  downloadExcel,
  canShowGraph,
  isSingleValue,
  hasResults,
  onAdjustChart,
  isChartAdjustVisible,
  isMobile
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-wrap items-center gap-1 sm:gap-2">
        {canShowGraph && (
          <Button
            onClick={toggleViewMode}
            icon={viewMode === 'chart' ? Table : BarChart}
            className="text-sm h-9 px-2 sm:px-3 py-2 text-left"
            style={{ width: 'auto', textAlign: 'left' }}
          >
            <span className="hidden sm:inline">
              {viewMode === 'chart' ? 'Table' : 'Graph'}
            </span>
          </Button>
        )}
        {showControls && viewMode === 'chart' && canShowGraph && (
          <>
            <ChartToggle chartType={chartType} onChange={setChartType} />
            <Button
              onClick={onAdjustChart}
              icon={Sliders}
              className={`text-sm h-9 px-2 sm:px-3 py-2 border border-secondary text-secondary hover:bg-secondary hover:text-white rounded-full transition-colors duration-200 ${
                isChartAdjustVisible ? 'bg-secondary text-white' : ''
              }`}
              title={isMobile ? undefined : "Adjust Chart"}
            >
              <span className="hidden sm:inline">Adjust</span>
            </Button>
          </>
        )}
      </div>
      {!isSingleValue && hasResults && (
        <Button
          onClick={downloadExcel}
          icon={Download}
          className="text-sm h-9 px-2 sm:px-3 py-2"
        >
          <span className="hidden sm:inline">Excel</span>
        </Button>
      )}
    </div>
  );
};

export default AlignedButtonLayout;