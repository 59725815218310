import React from 'react';
import { BarChart2, LineChart, PieChart } from 'lucide-react';
import Button from '../Button';

const ChartToggle = ({ chartType, onChange }) => {
  return (
    <>
      <Button
        onClick={() => onChange('bar')}
        icon={BarChart2}
        className={`text-sm h-9 px-2 sm:px-3 py-2 ${chartType === 'bar' ? 'bg-blue-100' : ''}`}
      >
        <span className="hidden sm:inline">Bar</span>
      </Button>
      <Button
        onClick={() => onChange('line')}
        icon={LineChart}
        className={`text-sm h-9 px-2 sm:px-3 py-2 ${chartType === 'line' ? 'bg-blue-100' : ''}`}
      >
        <span className="hidden sm:inline">Line</span>
      </Button>
      <Button
        onClick={() => onChange('pie')}
        icon={PieChart}
        className={`text-sm h-9 px-2 sm:px-3 py-2 ${chartType === 'pie' ? 'bg-blue-100' : ''}`}
      >
        <span className="hidden sm:inline">Pie</span>
      </Button>
    </>
  );
};

export default ChartToggle;