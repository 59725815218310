import React, { useMemo, useEffect, useState } from 'react';
import { BarChart, LineChart, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, Line, Pie, Cell, ResponsiveContainer } from 'recharts';
import { formatDate, formatNumber } from './dataProcessing';

const COLORS = ['#8884d8', '#FFADFF', '#170DF2', '#ff8042', '#0088FE', '#00C49F'];

const CustomPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text 
      x={x} 
      y={y} 
      fill="white" 
      textAnchor={x > cx ? 'start' : 'end'} 
      dominantBaseline="central"
      fontSize="12"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomLegend = ({ payload, onClick }) => {
  if (!payload || payload.length === 0) return null;

  return (
    <div style={{ 
      display: 'inline-flex', 
      flexWrap: 'wrap',
      justifyContent: 'center', 
      backgroundColor: 'rgba(255, 255, 255, 0.8)', 
      borderRadius: '5px', 
      padding: '5px',
      maxWidth: '100%',
    }}>
      {payload.map((entry, index) => (
        <div 
          key={`item-${index}`} 
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            margin: '0 5px',
            cursor: 'pointer'
          }}
          onClick={() => onClick(entry)}
        >
          <div style={{
            width: '10px',
            height: '10px',
            backgroundColor: entry.color,
            marginRight: '5px'
          }} />
          <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const ChartView = ({ data, schema, chartType, selectedXAxis, selectedYAxes, sortColumn, sortOrder }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [hiddenSeries, setHiddenSeries] = useState(new Set());

  const yAxes = useMemo(() => {
    const axes = Array.isArray(selectedYAxes) ? selectedYAxes : [];
    return axes.slice(0, 1);
  }, [selectedYAxes]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getSchemaType = (fieldName) => {
    const field = schema?.fields?.find(f => f.name === fieldName);
    return field ? field.type : null;
  };

  const formatAxisValue = (value, axisName) => {
    const schemaType = getSchemaType(axisName);
    if (schemaType && schemaType.toLowerCase().includes('date')) {
      return formatDate(value, schemaType);
    } else if (schemaType && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(schemaType.toUpperCase())) {
      return formatNumber(value);
    }
    return value;
  };

  const validData = useMemo(() => {
    if (!Array.isArray(data) || !selectedXAxis) return [];

    return data.map(item => {
      const newItem = {
        [selectedXAxis]: item[selectedXAxis]?.displayValue ?? item[selectedXAxis],
      };
      yAxes.forEach(yAxis => {
        newItem[yAxis] = item[yAxis]?.sortValue ?? item[yAxis];
      });
      return newItem;
    }).filter(item => yAxes.every(yAxis => typeof item[yAxis] === 'number' && !isNaN(item[yAxis])));
  }, [data, selectedXAxis, yAxes]);

  const getYDomain = (yAxis) => {
    if (validData.length === 0) return [0, 1];

    const values = validData.map(item => item[yAxis]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    if (minValue === maxValue) {
      return [0, maxValue * 1.1];
    }

    const padding = (maxValue - minValue) * 0.1;
    return [Math.max(0, minValue - padding), maxValue + padding];
  };

  const handleLegendClick = (e) => {
    if (e && e.dataKey) {
      setHiddenSeries(prev => {
        const newSet = new Set(prev);
        if (newSet.has(e.dataKey)) {
          newSet.delete(e.dataKey);
        } else {
          newSet.add(e.dataKey);
        }
        return newSet;
      });
    }
  };

  const getChartHeight = () => {
    const baseHeight = 450;
    if (isMobile) {
      return baseHeight * 0.7;
    } else {
      return baseHeight * 0.8;
    }
  };

  const commonProps = {
    data: validData,
    margin: isMobile
      ? { top: 20, right: 5, left: 5, bottom: 40 }
      : { top: 20, right: 10, left: 10, bottom: 40 }
  };

  const commonAxisProps = {
    tickFormatter: (value) => formatAxisValue(value, selectedXAxis),
    angle: -45,
    textAnchor: "end",
    height: 60,
    style: { fontSize: '12px', fontFamily: 'inherit' }
  };

  const legendProps = {
    onClick: handleLegendClick,
    content: <CustomLegend onClick={(entry) => handleLegendClick({ dataKey: entry.dataKey })} />,
    wrapperStyle: { position: 'relative', marginTop: '10px' }
  };

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart {...commonProps}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={selectedXAxis} {...commonAxisProps} />
            {yAxes.map((yAxis, index) => (
              <YAxis 
                key={yAxis}
                yAxisId={yAxis}
                tickFormatter={(value) => formatAxisValue(value, yAxis)}
                style={{ fontSize: '12px', fontFamily: 'inherit' }}
                domain={getYDomain(yAxis)}
                orientation={index === 0 ? "left" : "right"}
              />
            ))}
            <Tooltip 
              labelFormatter={(value) => formatAxisValue(value, selectedXAxis)}
              formatter={(value, name) => [formatAxisValue(value, name), name]}
            />
            <Legend {...legendProps} />
            {yAxes.map((yAxis, index) => (
              <Bar 
                key={yAxis} 
                dataKey={yAxis} 
                fill={COLORS[index % COLORS.length]} 
                yAxisId={yAxis}
                hide={hiddenSeries.has(yAxis)}
              />
            ))}
          </BarChart>
        );
      case 'line':
        return (
          <LineChart {...commonProps}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={selectedXAxis} {...commonAxisProps} />
            {yAxes.map((yAxis, index) => (
              <YAxis 
                key={yAxis}
                yAxisId={yAxis}
                tickFormatter={(value) => formatAxisValue(value, yAxis)}
                style={{ fontSize: '12px', fontFamily: 'inherit' }}
                domain={getYDomain(yAxis)}
                orientation={index === 0 ? "left" : "right"}
              />
            ))}
            <Tooltip 
              labelFormatter={(value) => formatAxisValue(value, selectedXAxis)}
              formatter={(value, name) => [formatAxisValue(value, name), name]}
            />
            <Legend {...legendProps} />
            {yAxes.map((yAxis, index) => (
              <Line 
                key={yAxis} 
                type="monotone" 
                dataKey={yAxis} 
                stroke={COLORS[index % COLORS.length]} 
                yAxisId={yAxis}
                hide={hiddenSeries.has(yAxis)}
              />
            ))}
          </LineChart>
        );
      case 'pie':
        const pieData = validData
          .map(item => ({
            name: item[selectedXAxis],
            value: item[yAxes[0]]
          }))
          .filter(item => item.value > 0);

        const total = pieData.reduce((sum, item) => sum + item.value, 0);

        return (
          <PieChart {...commonProps}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={isMobile ? "70%" : "80%"}
              fill="#8884d8"
              dataKey="value"
              label={<CustomPieLabel />}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip 
              formatter={(value, name) => [`${value} (${((value / total) * 100).toFixed(1)}%)`, name]}
            />
            <Legend {...legendProps} />
          </PieChart>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%', height: getChartHeight(), position: 'relative' }}>
      <ResponsiveContainer>
        {renderChart()}
      </ResponsiveContainer>
    </div>
  );
};

export default ChartView;